import React from 'react';

import Image from '../../components/Image';

import { Grid, Stack, Box, Link, Typography, Chip } from '@mui/material';

import { ButtonSmall } from '../../components/button';
import PartnerCategories from '../PartnerCategories';

const PartnerItem = ({
  slug,
  summary: { summary },
  categories,
  name,
  logoLight,
  logoDark,
  relatedPodcasts,
}) => (
  <Grid item xs={12} md={4}>
    <Stack>
      <Link to={`/partner/${slug}/`}>
        <Image mb={2} srcDark={logoDark} srcLight={logoLight} alt={name} />
      </Link>
      <Box>
        {relatedPodcasts && (
          <Chip
            label="Mit Podcast"
            size="small"
            color="success"
            sx={{ marginRight: 1 }}
          />
        )}
        <PartnerCategories categories={categories} />
        <Typography variant="h5" marginTop={1} gutterBottom>
          {name}
        </Typography>
        <Typography variant="body1" gutterBottom marginBottom={2}>
          {summary}
        </Typography>
        <ButtonSmall href={`/partner/${slug}/`}>Mehr erfahren</ButtonSmall>
      </Box>
    </Stack>
  </Grid>
);

export default PartnerItem;
