import PartnerItem from '../PartnerItem';

const PartnerList = ({ partner }) =>
  partner
    .sort((a, b) => {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();
      if (a === b) return 0;
      if (a > b) return 1;
      return -1;
    })
    .map((item) => PartnerItem(item));

export default PartnerList;
