import React from 'react';
import { graphql } from 'gatsby';

import Hero, { Tagline, Text, Title } from '../../sections/hero/hero';
import Layout from '../../components/layout/layout';
import CallToAction from '../../sections/CallToAction';
import { Grid } from '@mui/material';
import Section from '../../components/section';

import PartnerList from '../../components/PartnerList';
import PartnerCategories from '../../components/PartnerCategories';

const Partner = (props) => {
  const backgroundImage = props.data.background.childImageSharp.gatsbyImageData;
  const partner = props.data.partner.nodes;
  const categories = props.data.categories.nodes;

  return (
    <Layout
      title="Digital-Beratung aus Berlin"
      pathname={props.location.pathname}
    >
      <Hero backgroundImage={backgroundImage} backgroundColor="#EFEFEF">
        <Tagline>Partner-Netzwerk</Tagline>
        <Title>Unsere Partner stehen euch zur Verfügung</Title>
        <Text>
          Durch unsere Arbeit kennen wir viele Dienstleister der
          Versicherungsbranche.
        </Text>
        <Text>
          Damit ihr wisst, mit wem ihr die Herausforderungen der Zukunft angehen
          solltet, bringen wir euch mit den richtigen Partnern zusammen.
        </Text>
      </Hero>
      <Section>
        <PartnerCategories categories={categories} />
        <Grid container columnSpacing={2} rowSpacing={4}>
          <PartnerList partner={partner} />
        </Grid>
      </Section>
      <CallToAction
        title="Wir stellen euch die richtigen Partner vor"
        text="Vereinbart mit Jonas einen Termin und besprecht mit ihm eure Herausforderungen. Gemeinsam schaut ihr, welcher Partner für euch passend sein kann."
        label="Termin vereinbaren"
        href="https://calendar.app.google/1EZAsChBVHFGvZqG7"
      />
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    background: file(relativePath: { eq: "backgrounds/bg-consulting.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }

    partner: allContentfulPartner {
      nodes {
        slug
        targetAudience {
          targetAudience
        }
        summary {
          summary
        }
        partnerOffering {
          partnerOffering
        }
        name
        categories {
          name
          slug
        }
        relatedPodcasts
        logoLight {
          url
          gatsbyImageData(
            width: 500
            height: 300
            placeholder: BLURRED
            resizingBehavior: PAD
          )
          mimeType
        }
        logoDark {
          url
          gatsbyImageData(
            width: 500
            height: 300
            placeholder: BLURRED
            resizingBehavior: PAD
          )
          mimeType
        }
      }
    }
    categories: allContentfulCategories {
      nodes {
        name
        slug
      }
    }
  }
`;

export default Partner;
